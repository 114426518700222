import { createRouter, createWebHashHistory } from "vue-router";
import constant from "@/constant";
import Layout from "@/layout/Layout";
import Cookies from "js-cookie";
const routes = [
  {
    path: "/",
    name: "Login",
    id: "1",
    meta: { title: "用户登录", requireAuth: false },
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/main",
    id: "2",
    component: Layout,
    name: "工作台",
    icon: "el-icon-s-home",
    meta: { title: constant.title, requireAuth: true },
    redirect: "/home",
    children: [
      {
        path: "/home",
        id: "3",
        name: "工作台",
        icon: "el-icon-s-home",
        meta: { title: "工作台", requireAuth: true },
        component: () => import("@/views/Home.vue"),
      },
    ],
  },
  {
    path: "/orderlist",
    id: "4",
    icon: "el-icon-s-home",
    name: "订单列表",
    meta: { title: "订单列表", requireAuth: true },
    component: () => import("@/views/Orderlist.vue"),
  },
  {
    path: "/partners",
    id: "5",
    name: "分销店铺 ",
    meta: { title: "分销店铺 ", requireAuth: true },
    component: () => import("@/views/Partners.vue"),
  },
  {
    path: "/editpartners",
    id: "6",
    name: "编辑店铺 ",
    meta: { title: "编辑店铺 ", requireAuth: true },
    component: () => import("@/views/editpartners.vue"),
  },
  {
    path: "/products",
    id: "7",
    name: "商品管理 ",
    meta: { title: "商品管理 ", requireAuth: true },
    component: () => import("@/views/Products.vue"),
  },
  {
    path: "/salesmans",
    id: "8",
    name: "业务员统计2",
    meta: { title: "业务员统计2", requireAuth: true },
    component: () => import("@/views/Salesmans.vue"),
  },
  {
    path: "/blacklist2",
    id: "83",
    name: "黑名单2",
    meta: { title: "黑名单2", requireAuth: true },
    component: () => import("@/views/blacklist2.vue"),
  },
  {
    path: "/addpdts",
    id: "9",
    name: "新增商品 ",
    meta: { title: "新增商品 ", requireAuth: true, show: false },
    component: () => import("@/views/AddProducts.vue"),
  },
  {
    path: "/addplans",
    id: "10",
    name: "新增套餐 ",
    meta: { title: "新增套餐 ", requireAuth: true },
    component: () => import("@/views/AddPlanv2s.vue"),
  },
  {
    path: "/main2",
    id: "11",
    component: Layout,
    name: "个人资料",
    meta: { title: constant.title, requireAuth: true },
    redirect: "/personinfo",
    children: [
      {
        path: "/personinfo",
        id: "12",
        name: "个人资料",
        icon: "el-icon-user",
        meta: { title: "个人资料", requireAuth: true },
        component: () => import("@/views/PersonInfo.vue"),
      },
    ],
  },
  {
    path: "/permission",
    id: "15",
    component: Layout,
    name: "权限管理",
    icon: "el-icon-user",
    meta: { title: "权限管理", requireAuth: true },
    redirect: "/operator",
    children: [
      {
        path: "/operator",
        id: "17",
        name: "人员列表",
        icon: "el-icon-user",
        meta: { title: "人员列表", requireAuth: true, show: true },
        component: () => import("@/views/Operator.vue"),
      },
    ],
  },
  {
    path: "/product",
    id: "28",
    name: "商品管理",
    component: Layout,
    icon: "el-icon-goods",
    meta: { title: "商品管理", requireAuth: true },
    children: [
      {
        path: "/pdt",
        id: "29",
        name: "商品管理",
        meta: { title: "商品管理", requireAuth: true, show: true },
        component: () => import("@/views/Product.vue"),
      },
      {
        path: "/planlist",
        id: "31",
        name: "套餐管理",
        meta: { title: "套餐管理", requireAuth: true, show: true },
        component: () => import("@/views/Plan.vue"),
      },
      {
        path: "/stock",
        id: "32",
        name: "库存管理",
        meta: { title: "库存管理", requireAuth: true, show: true },
        component: () => import("@/views/Stock.vue"),
      },
    ],
  },
  // {
  //   path: "/information",
  //   id: "41",
  //   name: "通用信息管理",
  //   component: Layout,
  //   icon: "el-icon-info",
  //   meta: { title: constant.title, requireAuth: true },
  //   children: [
  //     {
  //       path: "/template",
  //       id: "42",
  //       name: "模板管理",
  //       meta: { title: "模板管理", requireAuth: true, show: true },
  //       component: () => import("@/views/Template.vue"),
  //     },
  //     {
  //       path: "/notice",
  //       id: "43",
  //       name: "公告管理",
  //       meta: { title: "公告管理", requireAuth: true, show: true },
  //       component: () => import("@/views/Notice.vue"),
  //     },
  //   ],
  // },
  {
    path: "/order",
    id: "46",
    name: "订单管理",
    component: Layout,
    icon: "el-icon-document-copy",
    meta: { title: constant.title, requireAuth: true },
    children: [
      {
        path: "/order",
        id: "47",
        name: "订单管理",
        meta: { title: "订单管理", requireAuth: true, show: true },
        component: () => import("@/views/Order.vue"),
      },
      {
        path: "/buyout",
        id: "49",
        name: "申请买断管理",
        meta: { title: "申请买断管理", requireAuth: true, show: true },
        component: () => import("@/views/Buyout.vue"),
      },
    ],
  },
  {
    path: "/finance",
    id: "54",
    name: "财务管理",
    component: Layout,
    icon: "el-icon-coin",
    meta: { title: constant.title, requireAuth: true },
    children: [
      {
        path: "/bill",
        id: "55",
        name: "交易流水",
        meta: { title: "交易流水", requireAuth: true, show: true },
        component: () => import("@/views/Bill.vue"),
      },
      {
        path: "/cashout",
        id: "57",
        name: "提现管理",
        meta: { title: "提现管理", requireAuth: true, show: true },
        component: () => import("@/views/Cashout.vue"),
      },
      {
        path: "/settle",
        id: "58",
        name: "结算管理",
        meta: { title: "结算管理", requireAuth: true, show: true },
        component: () => import("@/views/Settle.vue"),
      },
      {
        path: "/recharge",
        id: "59",
        name: "打款管理",
        meta: { title: "打款管理", requireAuth: true, show: true },
        component: () => import("@/views/recharge.vue"),
      },
      {
        path: "/rechargedetail",
        id: "60",
        name: "充值管理",
        meta: { title: "充值管理", requireAuth: true, show: true },
        component: () => import("@/views/rechargedetail.vue"),
      },
      {
        path: "/invoice",
        id: "63",
        name: "申请发票",
        meta: { title: "申请发票", requireAuth: true, show: true },
        component: () => import("@/views/Invoice.vue"),
      },
    ],
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = constant.title;
  }
  let token = Cookies.get("token");
  if (to.name != "Login") {
    if (!token || token == "undefined") {
      //没有登录过
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
