import { createApp, ref } from "vue";
import App from "./App.vue";
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
import "element-plus/lib/theme-chalk/display.css";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import zhcn from "element-plus/es/locale/lang/zh-cn";
ElementPlus.useLang = (app, ref, locale) => {
  const template = (str, option) => {
    if (!str || !option) return str;
    return str.replace(/\{(\w+)\}/g, (_, key) => {
      return option[key];
    });
  };
  app.provide("ElLocaleInjection", {
    lang: ref(locale.name),
    locale: ref(locale),
    t: (...args) => {
      const [path, option] = args;
      let value;
      const array = path.split(".");
      let current = locale;
      for (let i = 0, j = array.length; i < j; i++) {
        const property = array[i];
        value = current[property];
        if (i === j - 1) return template(value, option);
        if (!value) return "";
        current = value;
      }
    },
  });
};
const app = createApp(App).use(router);
ElementPlus.useLang(app, ref, zhcn);
app.use(ElementPlus);
app.use(VueAxios, axios);
app.mount("#app");
